import React, { Fragment } from "react";
import { Outlet } from "react-router-dom";

import LPNavbar from "components/LandingPage/Navbar/LPNavbar";
import LPFooter from "components/LandingPage/Footer/LPFooter";
import FloatingIcon from "components/LandingPage/FloatingIcon/FloatingIcon";

const LPLayout = () => (
  <Fragment>
    <LPNavbar />
    <Outlet />
    <FloatingIcon />
    <LPFooter />
  </Fragment>
);

export default LPLayout;
