import React from "react";
import parse, { DOMNode, Element, HTMLReactParserOptions } from "html-react-parser";

import SEO from "utils/SEO";

import LPJumbotron from "./Jumbotron/LPJumbotron";

interface DescriptionAndKeywords {
  desc: string;
  keywords: string;
}

const options: HTMLReactParserOptions = {
  replace: (domNode: DOMNode) => {
    if (domNode instanceof Element && domNode.attribs && domNode.attribs.class === "remove") {
      // eslint-disable-next-line react/jsx-no-useless-fragment, react/self-closing-comp
      return <React.Fragment></React.Fragment>;
    }
  }
};

const DESC_KEYWORDS: Record<string, DescriptionAndKeywords> = {
  "Araç Değer Kaybı": {
    desc: "Araç değer kayıplarınızda profesyonel süreçlerinizi sorunsuz işletiyoruz. Evraklarınızı tamamlıyor, hesaplamanızı yapıyoruz.",
    keywords:
      "adli danışmanlık, adli danışmanlık mehmet alparslan, araç değer kaybı, hasar, hasar kaydı, trafik kazası"
  },
  "Red Hasar Ödemeleri": {
    desc: "Kazada ki kusur durumuna göre sizin veya karşı tarafın zorunlu trafik sigortası kapsamında aracınızın hasarı zorunlu trafik sigortası kapsamında onarılmaktadır.",
    keywords:
      "adli danışmanlık, adli danışmanlık mehmet alparslan, red hasar ödemeleri, hasar, trafik kazası, kusur dağılımı"
  },
  "Hasar Ara Fark Ödemeleri": {
    desc: "Hasar ara fark ödemeleri, hasar bedel ya da hasar farkı tazminatı; kazalı araçta oluşan teknik nitelikteki zararın giderilmesi ile onarım giderlerine ilişkindir.",
    keywords:
      "adli danışmanlık, adli danışmanlık mehmet alparslan, hasar ara fark ödemeleri, hasar farkı tazminatı"
  },
  "Yaralanmalı Trafik Kazaları": {
    desc: "Yaralanmalı trafik kazalarında danışanlarımızın yanında olarak bütün maddi ve manevi haklarını savunup gerekli tazminatlarını talep edip güvence altına almaktayız.",
    keywords:
      "adli danışmanlık, adli danışmanlık mehmet alparslan, yaralanmalı trafik kazaları, trafik kazası, trafik kazası tazminatı"
  },
  "Kusur Dağılımı Değişimleri": {
    desc: "Kaza kusur oranları Tramer yardımıyla belirlenmektedir. Tramer ve kaza kusur oranlarının belirlenmesinde uzman kadromuz ile her zaman yanınızdayız.",
    keywords:
      "adli danışmanlık, adli danışmanlık mehmet alparslan, kusur dağılım değişimleri, trafik kazası, tramer"
  },
  "Pert Araç Ara Farkları": {
    desc: "Bir kaza sonucunda aracın %70 ve daha fazlasının hasar görmesi ve aracın onarım masraflarının sigorta değerine yakın olması onun “pert araç” olduğunu gösterir.",
    keywords:
      "adli danışmanlık, adli danışmanlık mehmet alparslan, pert araç, pert araç ara farkları, hasar"
  }
};

const LPContent = ({
  html,
  source,
  subTitle,
  title,
  fade
}: {
  html: string;
  source: string;
  title: string;
  subTitle: string;
  fade: string;
}) => (
  <div data-aos={fade}>
    <SEO
      description={DESC_KEYWORDS?.[title]?.desc ?? ""}
      keywords={DESC_KEYWORDS?.[title]?.keywords ?? ""}
      name="Adli Danışmanlık"
      pageTitle={`Adli Danışmanlık | ${title}`}
      type="article"
    />
    <LPJumbotron source={source} subTitle={subTitle} title={title} />
    <div className="container p-5 about-page-texts">{parse(html, options)}</div>
  </div>
);

export default LPContent;
