import React, { Suspense, lazy } from "react";

import Loader from "components/LandingPage/Loader/Loader";

import SEO from "utils/SEO";

const LPJumbotron = lazy(() => import("components/LandingPage/Content/Jumbotron/LPJumbotron"));
const LPContactCard = lazy(
  () => import("components/LandingPage/ContactForm/ContactCard/LPContactCard")
);

const LandingPageContact = () => (
  <Suspense fallback={<Loader />}>
    <main className="bg-gray-100 pb-5">
      <SEO
        description="Dava ve dosyalarınız hakkında bilgi almak ve danışmak için hafta içi her gün +90 543 373 45 38 numaralı telefondan ulaşım sağlayabilirsiniz. Whatsapp hattımız olan +90 543 544 93 38 numarasından 7 gün 24 saat ulaşım sağlayabilirsiniz."
        keywords="adli danışmanlık, adli danışmanlık şirketi, kayseri adli danışmanlık, mehmet alparslan, kayseri trafik kazası, trafik kazası, danışmanlık ofisi, araç değer kaybı, yaralanmalı kazalar"
        name="Adli Danışmanlık"
        pageTitle="Adli Danışmanlık | İletişim"
        type="article"
      />
      <LPJumbotron
        source="call.webp"
        subTitle="İletişim bilgilerimizi görebilir, bizlere mesaj atabilirsiniz ve haritadan hızlıca yol tarifi alıp bizi ziyaret edebilirsiniz."
        title="İletişim"
      />
      <div className="container col-12 pt-5">
        <p className="fw-bold text-center">
          {
            "Whatsapp ile 7/24, şirket telefonumuz ile hafta içi ve cumartesi günleri 09:00 ile 18:00 arasında iletişim kurabilirsiniz."
          }
        </p>
        <div className="row row-cols-1 row-cols-md-2 g-5 d-flex align-items-center">
          <LPContactCard
            key="phone-call"
            abbr="+90 (543) 373 45 38"
            alt="phone"
            fade="fade-left"
            href="tel:+90 (543) 373 45 38"
            linkText="Hemen Ara"
            source="phone.png"
            text="+90 (543) 373 45 38"
          />
          <LPContactCard
            key="send-mail"
            abbr="Mail Gönder"
            alt="letter"
            fade="fade-down"
            href="mailto:info@adlidanismanlik.com"
            linkText="Mail Gönder"
            source="letter.png"
            text="info@adlidanismanlik.com"
          />
          <LPContactCard
            key="find-location"
            abbr="Adres Tarifi"
            alt="location"
            fade="fade-right"
            href="https://www.google.com/maps/dir//38.7241943,35.4899042/@38.7241943,35.4877155,17z/data=!4m10!1m7!3m6!1s0x0:0x7fe9e4b68560abb7!2zMzjCsDQzJzI3LjEiTiAzNcKwMjknMjMuNyJF!3b1!8m2!3d38.7241943!4d35.4899042!4m1!3e2"
            linkText="Yol Tarifi Al"
            source="location.png"
            text="Adli Danışmanlık"
          />
        </div>
      </div>
    </main>
  </Suspense>
);

export default LandingPageContact;
