import React from "react";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";

import Logo from "assets/img/logo-new-2.png";

const NotFound = () => (
  <div className="full-height container-fluid">
    <div className="h-100 row d-flex align-items-stretch">
      <div className="col-12 d-flex align-items-start flex-column px-vw-5">
        <header className="mb-auto py-vh-2 col-12">
          <Link className="navbar-brand pe-4 fs-4" to="/">
            <LazyLoadImage
              alt="Site Logo"
              className="bi bi-layers-half"
              height="75"
              src={Logo}
              width="125"
            />
          </Link>
        </header>
        <main className="mb-auto col-12">
          <h1>{"404"}</h1>

          <h2 className="display-1 fw-bold">{"Uuups, yanlış giden bir şeyler var..."}</h2>
          <p className="lead">{"Aradığınız sayfa bulunamadı veya kaldırılmış."}</p>
          <Link className="link-fancy pt-4" to="/">
            <svg
              className="bi bi-arrow-left"
              fill="currentColor"
              height="16"
              viewBox="0 0 16 16"
              width="16"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
                fillRule="evenodd"
              />
            </svg>
            {" Ana sayfaya dönmek için tıklayınız."}
          </Link>
        </main>
      </div>
    </div>
  </div>
);

export default NotFound;
