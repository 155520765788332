import React, { useEffect } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import AOS from "aos";

import LandingPage from "pages/LandingPage/LandingPage";
import LandingPageService from "pages/LandingPage/LandingPageService";
import LandingPageContent from "pages/LandingPage/LandingPageContent";
import NotFound from "pages/NotFound/NotFound";
import LandingPageAbout from "pages/LandingPage/LandingPageAbout";
import LandingPageContact from "pages/LandingPage/LandingPageContact";
import LandingPageCase from "pages/LandingPage/LandingPageCase";

import LPLayout from "layouts/LPLayout";

import ScrollToTop from "utils/ScrollToTop";

import "aos/dist/aos.css";

import "assets/styles/theme.min.css";

const AppRouter = () => {
  useEffect(() => {
    AOS.init();

    window.gtag("config", "AW-11004727507");
  }, []);

  return (
    <Router>
      <ScrollToTop>
        <Routes>
          <Route element={<LPLayout />} path="/">
            <Route index element={<LandingPage />} />
            <Route element={<LandingPageService />} path="hizmetler" />
            <Route element={<LandingPageContent />} path="hizmetler/:id" />
            <Route element={<LandingPageAbout />} path="hakkimizda" />
            <Route element={<LandingPageContact />} path="iletisim" />
          </Route>
          <Route element={<LandingPageCase />} path="case/:ref" />
          <Route element={<NotFound />} path="*" />
        </Routes>
      </ScrollToTop>
    </Router>
  );
};

export default AppRouter;
