import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getMainContents = createAsyncThunk("getContents/main", async () => {
  const response = await axios(
    "https://api.adlidanismanlik.com/Content/GetSiteContent?PageName=main"
  );
  return response.data;
});

export const getServicesContents = createAsyncThunk("getContents/services", async () => {
  const response = await axios(
    "https://api.adlidanismanlik.com/Content/GetSiteContent?PageName=services"
  );
  return response.data;
});

export const getAboutContents = createAsyncThunk("getContents/about", async () => {
  const response = await axios(
    "https://api.adlidanismanlik.com/Content/GetSiteContent?PageName=about"
  );
  return response.data;
});
