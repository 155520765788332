import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

import LPContent from "components/LandingPage/Content/LPContent";
import Loader from "components/LandingPage/Loader/Loader";

import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";

import { RootState } from "store/store";
import { getServicesContents } from "store/contentStore/service";

import SEO from "utils/SEO";

const selectors = (state: RootState) => ({
  loading: state.content.servicesLoading,
  services: state.content.servicesContent
});

const LandingPageContent = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const { services, loading } = useAppSelector(selectors);

  useEffect(() => {
    dispatch(getServicesContents());
  }, []);

  if (loading) {
    return <Loader />;
  }

  const selectedService = services?.filter((item) => item.link === id)?.[0];

  return (
    <main>
      <SEO
        description="Temel olarak trafik kazalarını konu alan hasar değer kaybı, kusur dağılımı değişimleri, red hasar ödemeleri gibi birçok alanda hizmet sunmaktayız. Hizmetlerimiz sayfamızdan hizmetlerimizi inceleyebilir ve detaylı bilgi için iletişim kurabilirsiniz."
        keywords="adli danışmanlık, trafik kazası, araç değer kaybı, kaza, değer kaybı, yaralanmalı kazalar, trafik kazası danışmanlığı, red hasar ödemeleri, kusur dağılımı değişimi, pert araç ara farkları, hasar ara fark ödemeleri"
        name="Adli Danışmanlık"
        pageTitle="Adli Danışmanlık | Hizmetler"
        type="article"
      />
      <LPContent
        fade="fade-down"
        html={selectedService?.content ?? ""}
        source={`car${(Number(selectedService?.sectionName ?? 0) % 6) + 1}.webp`}
        subTitle={selectedService?.subTitle ?? ""}
        title={selectedService?.title ?? ""}
      />
    </main>
  );
};

export default LandingPageContent;
