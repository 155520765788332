import React from "react";
import { hydrate, render } from "react-dom";
import { HelmetProvider } from "react-helmet-async";
import { Provider } from "react-redux";

import AppRouter from "routes/AppRouter";

import "bootstrap/dist/js/bootstrap.bundle.min";

import "./index.css";

import { store } from "store/store";

import SEO from "utils/SEO";

import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

const rootElement = document.getElementById("root");

if (rootElement?.hasChildNodes()) {
  hydrate(
    <React.StrictMode>
      <HelmetProvider>
        <SEO
          description="Trafik kazalarını temel alan Profesyonel Adli Danışmanlık Hizmetleri sunmaktayız. Uzman ve tecrübeli personellerimiz tarafından işlemleriniz titizlikle yürütmekteyiz."
          keywords="adli danışmanlık, trafik kazası, araç değer kaybı, kaza, yaralanmalı kazalar, bireysel danışmanlık, kurumsal danışmanlık, trafik kazası danışmanlığı, danışmanlık şirketi, red hasar ödemeleri, kusur dağılımı değişimi"
          name="Adli Danışmanlık"
          pageTitle="Adli Danışmanlık | Mehmet Alparslan"
          type="article"
        />
        <Provider store={store}>
          <AppRouter />
        </Provider>
      </HelmetProvider>
    </React.StrictMode>,
    rootElement
  );
} else {
  render(
    <React.StrictMode>
      <HelmetProvider>
        <SEO
          description="Trafik kazalarını temel alan Profesyonel Adli Danışmanlık Hizmetleri sunmaktayız. Uzman ve tecrübeli personellerimiz tarafından işlemleriniz titizlikle yürütmekteyiz."
          keywords="adli danışmanlık, trafik kazası, araç değer kaybı, kaza, yaralanmalı kazalar, bireysel danışmanlık, kurumsal danışmanlık, trafik kazası danışmanlığı, danışmanlık şirketi, red hasar ödemeleri, kusur dağılımı değişimi"
          name="Adli Danışmanlık"
          pageTitle="Adli Danışmanlık | Mehmet Alparslan"
          type="article"
        />
        <Provider store={store}>
          <AppRouter />
        </Provider>
      </HelmetProvider>
    </React.StrictMode>,
    rootElement
  );
}

serviceWorkerRegistration.register();
