import React, { useEffect, useState } from "react";
import classnames from "classnames";
import { useMediaQuery } from "react-responsive";
import { useLocation, useNavigate } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";

import Logo from "assets/img/logo-new.png";
import Logo1 from "assets/img/logo-new-2.png";

import LPNavbarLinks from "./NavbarLinks/LPNavbarLinks";

const LPNavbar = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [show, setShow] = useState(false);
  const isSmallScreen = useMediaQuery({ query: "(max-width: 992px)" });
  const isHomePage = pathname === "/";

  const headerClassName = (): string => {
    if (show || (isSmallScreen && isHomePage)) {
      return "light";
    } else {
      return "dark";
    }
  };

  const fancyLinkClassName = () => {
    if (isHomePage) {
      return "dark";
    } else if (!isHomePage && !show && isSmallScreen) {
      return "light";
    } else if (!isHomePage && !show && !isSmallScreen) {
      return "light";
    }
    return "dark";
  };

  useEffect(() => {
    const handleScroll = () => {
      setShow(window.scrollY >= 112);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <nav
      className={classnames("navbar navbar-expand-lg fixed-top", {
        "scrolled shadow-lg": show,
        "navbar-dark": headerClassName() === "dark",
        "navbar-light": headerClassName() === "light",
        "bg-gray-900": !isHomePage
      })}
      id="navScroll"
      tabIndex={0}>
      <div className="container">
        <button className="navbar-brand pe-4 fs-4" onClick={() => navigate("/")}>
          <LazyLoadImage
            alt="Site Logo"
            className="bi bi-layers-half"
            height="75"
            src={show ? Logo1 : Logo}
            width="125"
          />
        </button>

        <button
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
          className="navbar-toggler"
          data-bs-target="#navbarSupportedContent"
          data-bs-toggle="collapse"
          type="button">
          <span className="navbar-toggler-icon" />
        </button>
        <LPNavbarLinks
          buttonClassName={classnames({
            "link-light link-fancy-light": fancyLinkClassName() === "light",
            "link-dark link-fancy": fancyLinkClassName() === "dark"
          })}
          collapseClassName={isSmallScreen && !isHomePage && !show ? "navbar-collapse-dark" : ""}
        />
      </div>
    </nav>
  );
};

export default LPNavbar;
