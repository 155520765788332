import React, { Suspense, lazy, useEffect } from "react";

import Loader from "components/LandingPage/Loader/Loader";

import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";

import { RootState } from "store/store";
import { getMainContents } from "store/contentStore/service";

import SEO from "utils/SEO";

const LPServices = lazy(() => import("components/LandingPage/Services/LPServices"));

const selectors = (state: RootState) => ({
  loading: state.content.mainLoading,
  main: state.content.mainContent
});

const LandingPageService = () => {
  const dispatch = useAppDispatch();
  const { main, loading } = useAppSelector(selectors);

  useEffect(() => {
    dispatch(getMainContents());
  }, []);

  if (loading) {
    return <Loader />;
  }

  const mainServices = main?.filter((item) => item.sectionName === "services")?.[0]?.services;

  return (
    <Suspense fallback={<Loader />}>
      <main>
        <SEO
          description="Trafik kazalarını temel alan hizmetlerimize göz atabilirsiniz. Hizmetlerimiz hakkında detaylı bilgi almak için +90 543 544 93 38 numrasından 7 gün 24 saat bizlere ulaşabilirsiniz."
          keywords="adli danışmanlık, trafik kazası, araç değer kaybı, kaza, hizmetler, danışmalık hizmeti, yaralanmalı kazalar, trafik kazası danışmanlığı, red hasar ödemeleri, kusur dağılımı değişimi, pert araç ara farkları, hasar ara fark ödemeleri"
          name="Adli Danışmanlık"
          pageTitle="Adli Danışmanlık | Hizmetler"
          type="article"
        />
        <LPServices className="py-5 text-dark bg-gray-300" services={mainServices} />
      </main>
    </Suspense>
  );
};

export default LandingPageService;
