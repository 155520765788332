import { configureStore } from "@reduxjs/toolkit";

import contentState from "./contentStore/contentState";
import caseState from "./caseStore/caseState";

export const store = configureStore({
  reducer: {
    content: contentState,
    case: caseState
  }
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
