import * as React from "react";
import { useLocation } from "react-router-dom";

type Props = {
  children?: React.ReactNode;
};

const ScrollToTop: React.FC<Props> = ({ children }) => {
  const location = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <React.Fragment>{children}</React.Fragment>;
};

export default ScrollToTop;
