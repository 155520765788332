import React from "react";
import { Link } from "react-router-dom";

const LPNavbarLink = ({ text, route }: { text: string; route: string }) => (
  <li className="nav-item">
    <Link className="nav-link" to={route}>
      {text}
    </Link>
  </li>
);

export default LPNavbarLink;
