import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import moment from "moment";

import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";

import { RootState } from "store/store";
import { changeModalVisibility } from "store/caseStore/caseState";

import SEO from "utils/SEO";

import Logo from "assets/img/logo-new-2.png";

const selectors = (state: RootState) => ({
  caseData: state.case.data,
  caseFiles: state.case.files
});

const LandingPageCase = () => {
  const { caseData, caseFiles } = useAppSelector(selectors);
  const { ref } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!caseData || !caseData?.referenceNumber || caseData?.referenceNumber !== ref) {
      navigateToHome();
    }
  }, []);

  const navigateToHome = () => {
    navigate(`/`);
    dispatch(changeModalVisibility(false));
  };

  return (
    <div className="full-height container-fluid">
      <SEO
        description="Temel amacımız bireysel veya kurumsal dava ve dosyalarınızı titizlikle takip edip lehinize sonuçlandırmaktır. Her zaman sitemizde bulunan sorgulama sayfasından dava ve dosyalarınızı sorgulayabilirsiniz. Telefon: +90 543 544 93 38"
        keywords="adli danışmanlık, trafik kazası, dava takibi, dosya takibi, danışmanlık şirketi, bireysel danışmanlık, kurumsal danışmanlık, hukuki süreçler, adli vakalar, kaza, yaralanmalı kazalar"
        name="Adli Danışmanlık"
        pageTitle="Adli Danışmanlık | Dosya"
        type="article"
      />
      <div className="h-100 row d-flex align-items-stretch">
        <div className="col-12 d-flex align-items-start flex-column px-vw-5">
          <header className="mb-auto py-vh-2 col-12">
            <button className="navbar-brand pe-4 fs-4" onClick={navigateToHome}>
              <LazyLoadImage
                alt="Site Logo"
                className="bi bi-layers-half"
                height="75"
                src={Logo}
                width="125"
              />
            </button>
          </header>
          <main className="mb-auto col-12">
            <h1>{caseData?.title}</h1>

            {caseData?.status === 1 ? (
              <p className="lead completed">{"Dava süreciniz tamamlanmıştır!"}</p>
            ) : (
              <p className="lead on-progress">{"Dava süreciniz devam etmektedir!"}</p>
            )}
            <p className="lead">
              <strong>{"Dava Açıklaması: "}</strong>
              {caseData?.startDescription}
            </p>
            {caseData?.terminateDescription ? (
              <React.Fragment>
                <p className="lead">
                  <strong>{"Dava Kapanış Açıklaması: "}</strong>
                  {caseData?.terminateDescription}
                </p>
                <p className="lead">
                  <strong>{"Dava Kapanış Tarihi: "}</strong>
                  {moment(caseData?.terminateDate).format("DD/MM/YYYY")}
                </p>
              </React.Fragment>
            ) : null}
            <p className="lead">
              <strong>{"Dava Dosyaları: "}</strong>
              {caseFiles?.length ? (
                <div className="d-flex flex-column align-items-start">
                  {caseFiles?.map((file, index) => (
                    <a
                      key={`${index}${file}`}
                      download
                      className="download-file-links"
                      href={`https://api.adlidanismanlik.com/${file}`}
                      rel="noreferrer"
                      target="_blank">{`Dosya ${index + 1}: ${
                      // eslint-disable-next-line wrap-regex
                      /.*\/([^.?]+)/.exec(file.replace(/\\/g, "/"))?.[1]
                    }`}</a>
                  ))}
                </div>
              ) : (
                "Dava için dosya eklenmemiş!"
              )}
            </p>
            <button className="link-fancy pt-4" onClick={navigateToHome}>
              <svg
                className="bi bi-arrow-left"
                fill="currentColor"
                height="16"
                viewBox="0 0 16 16"
                width="16"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
                  fillRule="evenodd"
                />
              </svg>
              {" Ana sayfaya dönmek için tıklayınız."}
            </button>
          </main>
        </div>
      </div>
    </div>
  );
};

export default LandingPageCase;
