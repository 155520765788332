import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import "./floating-icon.css";

const FloatingIcon = () => (
  <a className="float" href="https://api.whatsapp.com/send?phone=905435449338">
    <FontAwesomeIcon className="my-float" icon={faWhatsapp} />
    <p className="text">{"7 / 24"}</p>
  </a>
);

export default FloatingIcon;
