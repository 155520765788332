import classNames from "classnames";
import React from "react";

const LPNavbarButton = ({
  text,
  route,
  className
}: {
  text: string;
  route: string;
  className: string;
}) => (
  <a className={classNames("pb-1 me-2", className)} href={route}>
    {text}
  </a>
);

export default LPNavbarButton;
