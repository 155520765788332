import React, { Suspense, lazy } from "react";

import Loader from "components/LandingPage/Loader/Loader";

import SEO from "utils/SEO";

const LPContent = lazy(() => import("components/LandingPage/Content/LPContent"));

const html = `
      <p class="">
        Adli danışmanlık çeşitli nedenlerle meydana gelen trafik kazaları sonucunda ki maddi manevi mağduriyette her zaman yanınızda. 
      </p>
      <p>
        Kayseri merkezli Hasar Değer Kaybı, bireysel çözümlerin yanı sıra kurumsal çözümler de sunarak bilgilendirme hizmeti vermektedir. 
      </p>
      <p>
        Çift taraflı trafik kazasından kaynaklı tüm giderler hiçbir şekilde masraf talep etmeden Araç Değer Kaybı Danışmanı tarafından karşılanmaktadır.
      </p>
      <p>
        Kaza sonrasında ölüm ve yaralanmalarda maddi tazminat haklarınızı inceleyip poliçedeki teminatlar doğrultusunda almanız gereken tazminatı sorunsuz bir şekilde almaktadır.
      </p>`;

const LandingPageAbout = () => (
  <Suspense fallback={<Loader />}>
    <main>
      <SEO
        description="Şirketimiz temel olarak trafik kazalarına adli danışmanlık hizmeti vermek amacı ile kurulmuştur. Hasar değer kaybı, bireysel ve kurumsal çözümler ile hizmetinizdeyiz. Telefon: +90 543 544 93 38"
        keywords="adli danışmanlık, mehmet alparslan, kayseri kaza, kayseri trafik kazası, trafik kazası, danışmanlık, bireysel danışmanlık, kurumsal danışmanlık, kayseri danışmanlık"
        name="Adli Danışmanlık"
        pageTitle="Adli Danışmanlık | Hakkımızda"
        type="article"
      />
      <LPContent
        fade="fade-up"
        html={html}
        source="about.webp"
        subTitle="Mehmet Alparslan"
        title="Adli Danışmanlık"
      />
    </main>
  </Suspense>
);

export default LandingPageAbout;
