import React, { Suspense, lazy, useEffect, useMemo } from "react";
import { useMediaQuery } from "react-responsive";
import { LazyLoadImage } from "react-lazy-load-image-component";
import classNames from "classnames";

import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";

import { getMainContents } from "store/contentStore/service";
import { RootState } from "store/store";

import Logo from "assets/img/logo-new.png";

import Loader from "../Loader/Loader";

const LPFooterColumn = lazy(() => import("./FooterColumn/LPFooterColumn"));

const selectors = (state: RootState) => ({
  main: state.content.mainContent
});

const LPFooter = () => {
  const isSmallScreen = useMediaQuery({ query: "(min-width: 992px)" });
  const dispatch = useAppDispatch();
  const { main } = useAppSelector(selectors);

  useEffect(() => {
    dispatch(getMainContents());
  }, []);

  const mainServices = main
    ?.filter((item) => item.sectionName === "services")?.[0]
    ?.services?.slice(0, 6);

  const links = useMemo(
    () =>
      mainServices?.map((item) => ({
        linkTitle: item.title,
        linkRoute: `/hizmetler/${item.link}`
      })),
    [mainServices]
  );

  return (
    <Suspense fallback={<Loader />}>
      <footer className="bg-gray-900">
        <div className="container small text-light">
          <div className="row py-5 d-flex justify-content-between">
            <div
              className={classNames("col-12 col-lg-6 col-xl-3 p-5", {
                "border-end": isSmallScreen
              })}>
              <LazyLoadImage
                alt="Site Logo"
                className="bi bi-layers-half"
                height="75"
                src={Logo}
                width="125"
              />
              <address className="text-secondary mt-3">
                <strong>{"Sahabiye Mah,"}</strong>
                <br />
                {"Ahmet Paşa Cd."}
                <br />
                {"Adalet İş Merkezi. Kat: 3. No: 11"}
                <br />
                {"38010 Kocasinan/Kayseri"}
                <br />
                <abbr title="Şirket Telefon Numarası">{"Tel: +90 (543) 373 45 38"}</abbr>
              </address>
            </div>

            <LPFooterColumn
              className={classNames({ "border-end": isSmallScreen })}
              links={[
                { linkTitle: "Hizmetlerimiz", linkRoute: "/hizmetler" },
                { linkTitle: "Hakkımızda", linkRoute: "/hakkimizda" },
                { linkTitle: "İletişim", linkRoute: "/iletisim" }
              ]}
              title="Şirket"
            />
            <LPFooterColumn
              className={classNames({ "border-end": isSmallScreen })}
              links={links}
              title="Hizmetlerimiz"
            />
            <LPFooterColumn
              isAnchor
              links={[
                {
                  linkTitle: "Çerez Politikası",
                  linkRoute: "http://dokuman.adlidanismanlik.com/PDF/cerez-politikasi.pdf"
                },
                {
                  linkTitle: "Çerez Bilgilendirmesi",
                  linkRoute: "http://dokuman.adlidanismanlik.com/PDF/cerez-bilgilendirme.pdf"
                },
                {
                  linkTitle: "Aydınlatma Metni",
                  linkRoute: "http://dokuman.adlidanismanlik.com/PDF/aydinlatma-metni.pdf"
                },
                {
                  linkTitle: "Kişisel Veri Koruma, Saklama ve İmha Politikası",
                  linkRoute:
                    "http://dokuman.adlidanismanlik.com/PDF/kisisel-verilerin-koruma-saklama-imhasi.pdf"
                }
              ]}
              title="Genel Bilgiler"
            />
          </div>
        </div>

        <div className="container text-center text-light py-3 small">
          {"Coded by"}
          <a
            className="link-fancy-light text-light ps-1"
            href="https://github.com/MhmtMutlu"
            rel="noreferrer"
            target="_blank">
            {"Mehmet Mutlu"}
          </a>
        </div>
      </footer>
    </Suspense>
  );
};

export default LPFooter;
