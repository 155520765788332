import classNames from "classnames";
import React from "react";

import LPNavbarButton from "./LPNavbarButton";
import LPNavbarLink from "./LPNavbarLink";

const LPNavbarLinks = ({
  buttonClassName,
  collapseClassName
}: {
  buttonClassName: string;
  collapseClassName: string;
}) => (
  <div
    className={classNames("collapse navbar-collapse", collapseClassName)}
    id="navbarSupportedContent">
    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
      <LPNavbarLink route="/" text="Ana Sayfa" />
      <LPNavbarLink route="/hizmetler" text="Hizmetlerimiz" />
      <LPNavbarLink route="/hakkimizda" text="Hakkımızda" />
      <LPNavbarLink route="/iletisim" text="İletişim" />
    </ul>
    <LPNavbarButton
      className={buttonClassName}
      route="https://admin.adlidanismanlik.com/login/individual"
      text="Bireysel Giriş"
    />
    <LPNavbarButton
      className={buttonClassName}
      route="https://admin.adlidanismanlik.com/login/coorporate"
      text="Kurumsal Giriş"
    />
  </div>
);

export default LPNavbarLinks;
