import { createSlice } from "@reduxjs/toolkit";

import { getAboutContents, getMainContents, getServicesContents } from "./service";

interface ServiceContent {
  title: string;
  id: number;
  contentId: string;
  link: string;
}

interface Contents {
  id: string;
  createDate: string;
  updateDate: string;
  isActive: boolean;
  title: string;
  pageName: string;
  sectionName: string;
  subTitle: string;
  content: string;
  link?: string;
  services: Array<ServiceContent>;
}

interface InitialLoginState {
  mainContent: Array<Contents>;
  servicesContent: Array<Contents>;
  aboutContent: Array<Contents>;
  mainLoading: boolean;
  mainError: string;
  servicesLoading: boolean;
  servicesError: string;
  aboutLoading: boolean;
  aboutError: string;
}

interface ContentState {
  content: {
    mainContent: Array<Contents>;
    servicesContent: Array<Contents>;
    aboutContent: Array<Contents>;
    mainLoading: boolean;
    mainError: string;
    servicesLoading: boolean;
    servicesError: string;
    aboutLoading: boolean;
    aboutError: string;
  };
}

const initialState = {
  mainContent: [] as Array<Contents>,
  servicesContent: [] as Array<Contents>,
  aboutContent: [] as Array<Contents>,
  mainLoading: false,
  mainError: "",
  servicesLoading: false,
  servicesError: "",
  aboutLoading: false,
  aboutError: ""
} as InitialLoginState;

export const contentState = createSlice({
  name: "content",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // MAIN
    builder.addCase(getMainContents.pending, (state) => {
      state.mainLoading = true;
    });
    builder.addCase(
      getMainContents.fulfilled,
      (state, { payload }: { payload: Array<Contents> }) => {
        state.mainLoading = false;
        state.mainContent = payload;
      }
    );
    builder.addCase(getMainContents.rejected, (state, action) => {
      state.mainLoading = false;
      state.mainError = action?.error?.message ?? "Bir hata oluştu!";
    });
    // SERVICES
    builder.addCase(getServicesContents.pending, (state) => {
      state.servicesLoading = true;
    });
    builder.addCase(
      getServicesContents.fulfilled,
      (state, { payload }: { payload: Array<Contents> }) => {
        state.servicesLoading = false;
        state.servicesContent = payload;
      }
    );
    builder.addCase(getServicesContents.rejected, (state, action) => {
      state.servicesLoading = false;
      state.servicesError = action?.error?.message ?? "Bir hata oluştu!";
    });
    // ABOUT
    builder.addCase(getAboutContents.pending, (state) => {
      state.aboutLoading = true;
    });
    builder.addCase(
      getAboutContents.fulfilled,
      (state, { payload }: { payload: Array<Contents> }) => {
        state.aboutLoading = false;
        state.aboutContent = payload;
      }
    );
    builder.addCase(getAboutContents.rejected, (state, action) => {
      state.aboutLoading = false;
      state.aboutError = action?.error?.message ?? "Bir hata oluştu!";
    });
  }
});

export const getMainContent = (state: ContentState) => state.content.mainContent;
export const getServicesContent = (state: ContentState) => state.content.servicesContent;
export const getAboutContent = (state: ContentState) => state.content.aboutContent;

export default contentState.reducer;
