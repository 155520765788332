import React from "react";

const LPJumbotron = ({
  source,
  title,
  subTitle
}: {
  source: string;
  title: string;
  subTitle: string;
}) => (
  <div
    className="jumbotron img-cover"
    id="top"
    style={{
      backgroundImage: `linear-gradient(to left,
          rgba(0, 0, 0, 0) 20%,
          rgba(33, 37, 39, 0.7) 60%,
          rgba(33, 37, 41, 1) 100%), url(${require(`assets/img/${source}`)})`
    }}>
    <div className="container h-100 d-flex justify-content-start align-items-end pb-5">
      <div>
        <h1 className="text-light display-4 fw-bold">{title}</h1>
        <br />
        <p className="text-light">{subTitle}</p>
      </div>
    </div>
  </div>
);

export default LPJumbotron;
