import { createSlice } from "@reduxjs/toolkit";

import { getCase, getCaseFiles } from "./service";

interface Case {
  title: string;
  referenceNumber: string;
  startDescription: string;
  terminateDescription?: string;
  terminateDate?: string;
  status: number;
  companyID?: string;
  company?: {
    name: string;
    address: string;
    phone: string;
    email: string;
    createDate: string;
    isActive: true;
    id: string;
  };
  user: {
    id: string;
    name: string;
    surname: string;
    email: string;
    type: number;
    identityNumber: string;
    mustChangePassword: true;
    companyID: string;
  };
}

interface InitialCaseState {
  data: Case;
  loadingData: boolean;
  errorData: string;
  modalVisibility: boolean;
  loadingFiles: boolean;
  errorFiles: string;
  files: Array<string>;
}

const initialState = {
  data: {},
  errorData: "",
  loadingData: false,
  modalVisibility: false,
  errorFiles: "",
  loadingFiles: false,
  files: [] as Array<string>
} as InitialCaseState;

export const caseState = createSlice({
  name: "case",
  initialState,
  reducers: {
    resetStates: () => initialState,
    changeModalVisibility: (state, action) => {
      state.modalVisibility = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getCase.pending, (state) => {
      state.loadingData = true;
    });
    builder.addCase(getCase.fulfilled, (state, action) => {
      state.loadingData = false;
      state.data = action.payload;
    });
    builder.addCase(getCase.rejected, (state, action) => {
      state.loadingData = false;
      state.errorData = action?.error?.message ?? "Bir hata oluştu!";
    });
    builder.addCase(getCaseFiles.pending, (state) => {
      state.loadingFiles = true;
    });
    builder.addCase(getCaseFiles.fulfilled, (state, action) => {
      state.loadingFiles = false;
      state.files = action.payload;
    });
    builder.addCase(getCaseFiles.rejected, (state, action) => {
      state.loadingFiles = false;
      state.errorFiles = action?.error?.message ?? "Bir hata oluştu!";
    });
  }
});

export const { resetStates, changeModalVisibility } = caseState.actions;
export default caseState.reducer;
