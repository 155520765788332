import React from "react";
import { Helmet } from "react-helmet-async";

const SEO = ({
  pageTitle,
  description,
  name,
  type,
  keywords
}: {
  pageTitle: string;
  description: string;
  name: string;
  type: string;
  keywords: string;
}) => (
  <Helmet>
    {/* Standard metadata tags */}
    <link href="https://www.adlidanismanlik.com/" rel="canonical" />
    <title>{pageTitle}</title>
    <meta content={description} name="description" />
    <meta content={keywords} name="keywords" />
    {/* End standard metadata tags */}
    {/* Facebook tags */}
    <meta content={type} property="og:type" />
    <meta content={pageTitle} property="og:title" />
    <meta content={description} property="og:description" />
    {/* End Facebook tags */}
    {/* Twitter tags */}
    <meta content={name} name="twitter:creator" />
    <meta content={type} name="twitter:card" />
    <meta content={pageTitle} name="twitter:title" />
    <meta content={description} name="twitter:description" />
    {/* End Twitter tags */}
  </Helmet>
);

export default SEO;
