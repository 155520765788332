import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getCase = createAsyncThunk(
  "getCase/main",
  async ({ tckn, refNumber }: { tckn: string; refNumber: string }) => {
    const response = await axios(
      `https://api.adlidanismanlik.com/Case/GetMyCasesForMain?TcNo=${tckn}&ReferenceNumber=${refNumber}`
    );
    return response.data;
  }
);

export const getCaseFiles = createAsyncThunk(
  "getCaseFiles/main",
  async ({ refNumber }: { refNumber: string }) => {
    const response = await axios(
      `https://api.adlidanismanlik.com/Case/GetAllCaseFiles?ReferenceNumber=${refNumber}`
    );
    return response.data;
  }
);
