import React, { Suspense, lazy, useEffect } from "react";
import { ToastContainer } from "react-toastify";

import Loader from "components/LandingPage/Loader/Loader";

import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";

import { getMainContents } from "store/contentStore/service";
import { RootState } from "store/store";

import SEO from "utils/SEO";

const LPHeader = lazy(() => import("components/LandingPage/Header/LPHeader"));
const LPInfo = lazy(() => import("components/LandingPage/Info/LPInfo"));
const LPContactForm = lazy(() => import("components/LandingPage/ContactForm/LPContactForm"));
const LPServices = lazy(() => import("components/LandingPage/Services/LPServices"));

const selectors = (state: RootState) => ({
  loading: state.content.mainLoading,
  main: state.content.mainContent
});

const LandingPage = () => {
  const dispatch = useAppDispatch();
  const { main, loading } = useAppSelector(selectors);

  useEffect(() => {
    dispatch(getMainContents());
  }, []);

  if (loading) {
    return <Loader />;
  }

  const mainHeader = main?.filter((item) => item.sectionName === "header")?.[0];
  const mainServices = main
    ?.filter((item) => item.sectionName === "services")?.[0]
    ?.services?.slice(0, 6);

  return (
    <Suspense fallback={<Loader />}>
      <main>
        <SEO
          description="Profesyonel Adli Danışmanlık Hizmetleri. Uzman ve tecrübeli personellerimiz tarafından işlemleriniz titizlikle yürütülür."
          keywords="adli danışmanlık, trafik kazası, araç değer kaybı, kaza, adli, danışmanlık, değer kaybı, yaralanmalı kazalar, bireysel danışmanlık, kurumsal danışmanlık, trafik kazası danışmanlığı, danışmanlık şirketi"
          name="Adli Danışmanlık"
          pageTitle="Adli Danışmanlık | Mehmet Alparslan"
          type="article"
        />
        <LPHeader
          content={mainHeader?.content}
          subTitle={mainHeader?.subTitle}
          title={mainHeader?.title}
        />
        <LPInfo />
        <LPServices services={mainServices} />
        <LPContactForm />

        <ToastContainer autoClose={8000} />
      </main>
    </Suspense>
  );
};

export default LandingPage;
